@import 'src/design-system/styles/layout-variables';
@import 'src/modules/Betting/grid.variables.scss';

$content-margin-top: $content-indent-top - $grid-header-indent-top;

.content {
  position: relative;
  margin-top: $content-margin-top;
}

.sportContainer {
  margin: $content-indent;
  margin-top: 0;
}
