$screen-size-sm: 1280px;

$grid-column-screen-width: 1380px;

$content-width: 760px;
$content-indent-top: 16px;
$content-indent-right: 24px;
$content-indent-bottom: 24px;
$content-indent-left: 24px;
$content-indent: $content-indent-top $content-indent-right $content-indent-bottom $content-indent-left;

$sidebar-width: 260px;

$left-menu-width: 260px;
$left-menu-indent-top: 16px;
$left-menu-indent-bottom: 16px;
$left-menu-padding-top: 16px;
$left-menu-padding-right: 13px;
$left-menu-padding-bottom: 8px;
$left-menu-padding-left: 12px;

$main-header-height: 60px;
$main-search-height: 56px;

$height-without-header: calc(100vh - #{$main-header-height});
